import { Box, Dialog, Typography } from '@mui/material';
import { Transitionleft } from 'src/config/Transition';
import imagenerror from '../../../../assets/gifs/ALERTA CARTA_112X112.gif';
import { FC } from 'react';
import ButtonText from '../Buttons/ButtonText';

interface Props {
  open: boolean;
  handleClose: () => void;
  isDarkMode?: Boolean;
  handleCerrarSesion: any;
}

export const DialogCerrarSesion: FC<Props> = ({
  open,
  handleClose,
  handleCerrarSesion
}) => {
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      maxWidth={'xl'}
      disableScrollLock={false}
      TransitionComponent={Transitionleft}
      PaperProps={{
        sx: {
          boxShadow: 'none',
          background: '#FFF',
          borderRadius: '12px',
          display: 'flex',
          width: {
            xs: '320px',
            sm: '320px',
            md: '420px'
          },
          padding: {
            xs: '24px 20px',
            sm: '24px 20px',
            md: '28px'
          },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: {
            xs: '28px',
            sm: '28px',
            md: '32px'
          }
        }
      }}
      slotProps={{
        backdrop: {
          sx: {
            opacity: '0.20 !important', // siempre incluir en la opacidad
            backgroundColor: '#232323' // siempre incluir en la opacidad
          }
        }
      }}
    >
      {/*contenido */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
          alignSelf: 'stretch'
        }}
      >
        <Box
          component="img"
          src={imagenerror}
          sx={{
            width: {
              xs: '120px',
              sm: '120px',
              md: '140px'
            },
            height: {
              xs: '120px',
              sm: '120px',
              md: '140px'
            }
          }}
        />
      </Box>

      <Typography
        sx={{
          color: '#2D94B4',
          fontFamily: 'Montserrat',
          fontWeight: 400,
          fontSize: {
            xs: '20px',
            sm: '20px',
            md: '24px'
          },
          lineHeight: '120%',
          textAlign: 'center',
          alignSelf: 'stretch'
        }}
      >
        ¿Deseas terminar tu sesión?
      </Typography>

      {/*Botones */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: '16px',
          alignSelf: 'stretch'
        }}
      >
        <ButtonText
          height={{
            xs: '48px',
            sm: '48px',
            md: '56px'
          }}
          width={{
            xs: '100px',
            sm: '100px',
            md: '108px'
          }}
          onClick={handleClose}
          title="volver"
          colorTitle={'#FFFFFF'}
          active={false}
          propina={true}
          bgColor={'#2FB699'}
          hoverColor={'#69C4DE'}
          iconHcolor={'#FFFFFF'}
          iconNcolor={'#FFFFFF'}
          fontSize={'15px'}
        />
        <ButtonText
          height={{
            xs: '48px',
            sm: '48px',
            md: '56px'
          }}
          width={{
            xs: '172px',
            sm: '172px',
            md: '240px'
          }}
          onClick={handleCerrarSesion}
          title="Sí, cerrar"
          colorTitle={'#FFFFFF'}
          active={true}
          bgColor={'#2FB699'}
          hoverColor={'#69C4DE'}
          iconHcolor={'#FFFFFF'}
          iconNcolor={'#2FB699'}
          fontSize={'15px'}
        />
      </Box>
    </Dialog>
  );
};
export default DialogCerrarSesion;
