import * as React from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Icon, IconButton, Typography } from '@mui/material';
import cerrarAmbar from '../../../../assets/icons/SIDEBAR/CERRAR-ALERTA_40X40.svg';
import cerrarRojo from '../../../../assets/icons/SIDEBAR/CERRAR-ERROR_40X40.svg';
import { PaperProps } from '@mui/material/Paper';

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  estado: string;
  fVencimiento: any;
  paper: PaperProps;
  modalLocales: boolean;
  setmodalLocales: (value?: boolean) => void;
  datos: any[];
  handleArrayLocal?: any;
  // response: ResponseLogin;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function ModalAlerta(props: ConfirmationDialogRawProps) {
  const {
    onClose,
    value: valueProp,
    estado,
    fVencimiento,
    paper,
    open,
    modalLocales,
    setmodalLocales,
    datos,
    handleArrayLocal,
    ...other
  } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleAbreRenovacion = () => {
    handleArrayLocal();
    setmodalLocales(true);
    onClose();
  };

  return (
    <Dialog
      sx={{
        width: { xs: '100%', sm: '100%', md: '45%', lg: '40%', xl: '40%' },
        justifySelf: 'right',
        '& .MuiDialog-paper': {
          backgroundColor: '#00000000'
        },
        '.MuiDialog-container': {
          width: 'max-contente',
          justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'right',
            lg: 'right',
            xl: 'right'
          },
          alignItems: {
            xs: 'end',
            sm: 'end',
            md: 'flex-start',
            lg: 'flex-start',
            xl: 'flex-start'
          }
        },
        '.MuiDialogContent-root': {
          padding: '0px',
          justifyContent: 'right',
          alignItems: 'end'
        }
      }}
      maxWidth="xl"
      // TransitionComponent={(direction === 'right') ? TransitionLeft : TransitionRight}
      TransitionProps={{ onEntering: handleEntering }}
      PaperProps={paper}
      open={open}
      {...other}
      BackdropProps={{
        sx: {
          opacity: '0.4 !important',
          backgroundColor: '#232323',
          '.MuiDialog-container': {
            width: 'max-contente',
            float: 'right',
            // alignItems: 'flex-start',
            justifyContent: 'right',
            alignItems: 'end'
          },
          '.MuiDialogContent-root': {
            padding: '0px',
            justifyContent: 'right',
            alignItems: 'end'
          }
        }
      }}
    >
      <DialogContent
        sx={{
          padding: {
            xs: '24px 20px',
            sm: '24px 20px',
            md: '28px',
            lg: '28px',
            xl: '28px'
          },
          float: 'right'
        }}
      >
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'flex',
              lg: 'flex',
              xl: 'flex'
            }
          }}
        >
          <Box
            alignSelf={'center'}
            sx={{
              pr: { xs: '0px', sm: '0px', md: '20px', lg: '20px', xl: '20px' },
              pt: { xs: '12px', sm: '12px', md: '0px', lg: '0px', xl: '0px' },
              textAlign: 'center'
            }}
          >

            <img
              src={estado == '1' || estado == '3' ? cerrarAmbar : cerrarRojo}
              onClick={handleCancel}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                borderRadius: '8px 0px 0px 8px',
                border:
                  estado == '1' || estado == '3'
                    ? '1px solid var(--color-marca-mostaza-400, #FBD6C5)'
                    : estado == '2'
                    ? '1px solid var(--color-marca-rojo-400, #FFD6D3)'
                    : '',
                background:
                  estado == '1' || estado == '3'
                    ? 'var(--color-marca-mostaza-50, #FFFAF7)'
                    : estado == '2'
                    ? 'var(--color-marca-rojo-50, #FFF6F5)'
                    : '',
                display: 'flex',
                padding: '16px 20px 16px 0px',
                alignItems: 'center'
                // gap: '20px',
              }}
            >
              <Box
                gap={'20px'}
                sx={{
                  pl: {
                    xs: '12px',
                    sm: '12px',
                    md: '20px',
                    lg: '20px',
                    xl: '20px'
                  }
                }}
              >
                <Typography
                  sx={{
                    color:
                      estado == '1' || estado == '3'
                        ? 'var(--color-marca-mostaza-900, #FFA826)'
                        : estado == '2'
                        ? 'var(--color-marca-rojo-900, #F44336)'
                        : '',
                    /* MOVIL/CUERPO/PARRAFO B */
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  }}
                >
                  {estado == '1' || estado == '3'
                    ? 'Su certificado vence el '
                    : estado == '2'
                    ? 'Su certificado venció el '
                    : 'Su certificado vence el '}
                  {fVencimiento}
                </Typography>
                {estado == '1' || estado == '3' ? (
                  <Typography
                    sx={{
                      color: ' var(--color-neutral-texto, #1E2947)',
                      fontFamily: 'Poppins',
                      fontSize: {
                        xs: '14px',
                        sm: '14px',
                        md: '16px',
                        lg: '16px',
                        xl: '16px'
                      },
                      fontStyle: 'normal',
                      fontWeight: 300,
                      lineHeight: {
                        xs: '20px',
                        sm: '20px',
                        md: '24px',
                        lg: '24px',
                        xl: '24px'
                      }
                    }}
                  >
                    Su certificado digital está próximo a vencer. No olvide
                    renovarlo para gestionar su facturación sin problemas.{' '}
                  </Typography>
                ) : estado == '2' ? (
                  <Typography
                    sx={{
                      color: ' var(--color-neutral-texto, #1E2947)',
                      fontFamily: 'Poppins',
                      fontSize: {
                        xs: '14px',
                        sm: '14px',
                        md: '16px',
                        lg: '16px',
                        xl: '16px'
                      },
                      fontStyle: 'normal',
                      fontWeight: 300,
                      lineHeight: {
                        xs: '20px',
                        sm: '20px',
                        md: '24px',
                        lg: '24px',
                        xl: '24px'
                      }
                    }}
                  >
                    Su certificado digital se encuentra vencido. Renuévelo para
                    gestionar su facturación con normalidad.
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            color:
              estado == '1' || estado == '3'
                ? 'var(--color-marca-mostaza-900, #FFA826)'
                : estado == '2'
                ? 'var(--color-marca-rojo-900, #F44336)'
                : '',
            textAlign: 'right',
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            textDecorationLine: 'underline',
            padding: '20px',
            cursor: 'pointer'
          }}
          onClick={handleAbreRenovacion}
        >
          ¡Renuévalo aquí!
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default ModalAlerta;
