//BUSQUEDA
import CerrarActivo from '../assets/icons/BUSQUEDA/CERRAR_ACTIVO_24X24.svg';
import Busqueda from '../assets/icons/BUSQUEDA/BUSCAR_MINI_ACTIVO_18X18.svg';
//HEADER
import FlechaArribaPasivo from '../assets/icons/HEADER/FLECHA_ARRIBA_PASIVO_24X24.svg';
import FlechaArribaActivo from '../assets/icons/HEADER/FLECHA_ARRIBA_ACTIVO_24X24.svg';
import FlechaAbajoActivo from '../assets/icons/HEADER/FLECHA_ABAJO_ACTIVO_24X24.svg';
import FlechaAbajoPasivo from '../assets/icons/HEADER/FLECHA_ABAJO_PASIVO_24X24.svg';

//MODALES
import FlechaPequeñaActiva from '../assets/icons/MODALES/FELCHA_HOVER_PEQUEÑA_10X6.svg';
import FlechaPequeñaHover from '../assets/icons/MODALES/FLECHA-ACTIVO-PEQUEÑA_24X24.svg';
import CheckboxPasivo from '../assets/icons/MODALES/CHECKBOX_WHILE_24X24.svg';
import FlechaDerecha from '../assets/icons/MODALES/FLECHA_DERECHA_24X24.svg';
import FlechaIzquierda from '../assets/icons/MODALES/FLECHA_IZQUIERDA_24X24.svg';
import Mas from '../assets/icons/MODALES/MAS_24X24.svg';
import Guardar from '../assets/icons/MODALES/GUARDAR_24X24.svg';
import ArchivoListaCard from '../assets/icons/MODALES/ACHIVO_LISTA_CARD_24X24.svg';
import DocumentoCheck from '../assets/icons/MODALES/DOCUMENTO_CHECK_24X24.svg';

//MODAL
import CalendarioBuscar from '../assets/icons/MODAL/CALENDARIO_BUSCAR_2020X240.svg';


//TABLAS
import FlechaNarangaAbajo from '../assets/icons/TABLAS/FLECHA_ORDENAR_ABAJO_16X16.svg';
import FlechaNarangaArriba from '../assets/icons/TABLAS/FLECHA_ORDENAR_ARRIBA_16X16.svg';
import FlechaNarangaPasivo from '../assets/icons/TABLAS/FLECHA_ORDENAR_PASIVO_16X16.svg';

const Icons = {
  //BUSQUEDA
  cerrarActivo: CerrarActivo,
  busqueda: Busqueda,
  //HEADER
  flechaArribaActivo: FlechaArribaActivo,
  flechaArribaPasivo: FlechaArribaPasivo,
  flechaAbajoActivo: FlechaAbajoActivo,
  flechaAbajoPasivo: FlechaAbajoPasivo,

  //MODALES
  flechaPequeñaActiva: FlechaPequeñaActiva,
  flechaPequeñaHover: FlechaPequeñaHover,
  checkboxPasivo: CheckboxPasivo,
  flechaDerecha: FlechaDerecha,
  flechaIzquierda: FlechaIzquierda,
  mas: Mas,
  guardar: Guardar,
  archivoListaCard:ArchivoListaCard,
  documentoCheck:DocumentoCheck,

  //TABLAS
  flechaNarangaAbajo: FlechaNarangaAbajo,
  flechaNarangaArriba: FlechaNarangaArriba,
  flechaNarangaPasivo: FlechaNarangaPasivo,

  //MODAL
  calendarioBuscar: CalendarioBuscar
};

const lightColors = {
  Pasivo: '#FFFFFF',
  Hover: '#69C4DE',
  // Hover: "#79DD8B", //verde Hover
  Activo: '#2FB699',
  //Activo: '#80CFBC',
  //Activo: "#42C67E",//verde
  //Gris
  Neutro: '#88919E',
  TitleGris: '#717883',
  //colores claros
  ClaroHover: '#F6FBFA',
  Claro: '#E8F6F3',
  //Alertas
  ErrorPasivo: '#FF655A',
  ErrorHover: '#F44336',
  ErrorHoverClaro: '#FFECEB',
  Exito: '#42C67E',
  Informacion: '#2D94B4',
  //texto
  Title: '#2AA288',
  TitleHover: '#FCA422',
  //lINEA
  lineaModal: '#80CFBC',
  lineaTabla: '#ADE0D4',
  //circulos
  Circular: '#2FB699'
};

const darkColors = {
  Pasivo: '#121212',
  Hover: '#69C4DE',
  Activo: '#80CFBC',
  //Gris
  Neutro: '#88919E',
  TitleGris: '#717883',
  //Claro
  ClaroHover: '#F6FBFA',
  Claro: '#E8F6F3',
  //Alertas
  ErrorPasivo: '#FF655A',
  ErrorHover: '#F44336',
  ErrorHoverClaro: '#FFECEB',
  Exito: '#42C67E',
  Informacion: '#2D94B4',
  //text
  Title: '#2AA288',
  TitleHover: '#FCA422',
  //lINEA
  lineaModal: '#80CFBC',
  lineaTabla: '#ADE0D4',
  //circulos
  Circular: '#2FB699'
};

export const themes = {
  light: {
    icons: Icons,
    colors: lightColors
    // Lotties: LottiesIcons,
    // logo: LogoIcons,
    // imagen: Imagen,
  },
  dark: {
    icons: Icons,
    colors: darkColors
    // Lotties: LottiesIcons,
    // logo: LogoIcons,
    // imagen: Imagen,
  }
};
