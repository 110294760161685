import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Box, Drawer, styled, useTheme } from '@mui/material';
import SidebarMenu from './SidebarMenu';
import { ResponseLogin } from 'src/models/login';

interface Props {
  window?: () => Window;
}

const Sidebar = (props: Props) => {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const response: ResponseLogin = JSON.parse(
    localStorage.getItem('responseLogin')
  );
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'inline-block'
          },
          position: 'absolute',
          zIndex: 10,
          top: 0,
          left: 0,
          width: '68px',
          height: '100vh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <SidebarMenu response={response}></SidebarMenu>
      </Box>
      <Drawer
        sx={{
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none'
          }
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <Box
          sx={{
            background: 'white',
            boxShadow: ' 4px 0px 20px 0px rgba(25, 67, 127, 0.2)'
          }}
        >
          <Scrollbar>
            <SidebarMenu response={response} />
          </Scrollbar>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
