import { useContext, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  ListItemButton,
  Paper
} from '@mui/material';
import {  useNavigate } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { ResponseLogin } from 'src/models/login';
import { TokenContext } from 'src/contexts/UserContext';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@material-ui/core';
import inicioPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-INICIO-PASIVO_68X56.svg';
import inicioActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-INICIO-ACTIVO_68X56.svg';
import inicioHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-INICIO-HOVER_68X56.svg';
import docPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-PASIVO_68X56.svg';
import docActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-ACTIVO_68X56.svg';
import docHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-COMPROBANTE-HOVER_68X56.svg';
import bajaActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-ACTIVO_68X56.svg';
import bajaPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-PASIVO_68X56.svg';
import bajaHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-BAJAS-HOVER_68X56.svg';
import guiaPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-GUIAS-PASIVO_68X56.svg';
import guiaActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-GUIAS-ACTIVO_68X56.svg';
import guiaHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-GUIAS-HOVER_68X56.svg';
import cobranzaPasivo from '../../../../assets/icons/SIDEBAR/LOCALES-PASIVO_68X56.svg';
import cobranzaActivo from '../../../../assets/icons/SIDEBAR/LOCALES-ACTIVO_68X56.svg';
import cobranzaHover from '../../../../assets/icons/SIDEBAR/LOCALES-HOVER_68X56.svg';
import repositorioPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-REPOSITORIO-PASIVO_68X56.svg';
import repositorioActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-REPOSITORIO-ACTIVO_68X56.svg';
import repositorioHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-REPOSITORIO-HOVER_68X56.svg';
import sbClose from '../../../../assets/icons/SIDEBAR/SIDEBAR CERRAR_52X48.svg';
import { themes } from 'src/config/Icons';
import ButtonSidebar from 'src/content/pages/Components/Buttons/ButtonSidebar';
import ConsultasPasivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-DEUDAS-PASIVO_68X56.svg';
import ConsultasActivo from '../../../../assets/icons/SIDEBAR/SIDEBAR-DEUDAS-ACTIVO_68X56.svg';
import ConsultasHover from '../../../../assets/icons/SIDEBAR/SIDEBAR-DEUDAS-HOVER_68X56.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      zIndex: 1,
      backgroundColor: 'white',
      position: 'fixed',
      boxShadow: '4px 0px 10px 0px rgba(25, 67, 127, 0.12)',
      '& .MuiButtonBase-root': {
        padding: 0
      },
      '& .MuiList-root': {
        padding: '0px'
      }
    },
    paper: {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      padding: 0,
      '&.MuiPaper-root': {
        boxShadow: '8px 8px 10px 0px rgba(25, 67, 127, 0.10)',
        position: 'absolute',
        top: 0,
        zIndex: 1,
        padding: 0
      }
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      position: 'fixed',
      '&.MuiListItem-root': {
        justifyContent: 'center',
        padding: '0px',
        '&:hover': {
          backgroundColor: 'var(--colorQuaternary-Green)'
        }
      }
    }
  })
);

interface Props {
  response: ResponseLogin;
  isDarkMode?: Boolean;
}

const SidebarMenu = ({ response, isDarkMode }: Props) => {
  const classes = useStyles();
  const { idSelectedIndexMenu,selectedIndexMenu, indexRuta, idindexRuta } =
    useContext(TokenContext);
  const [hoveredItem, setHoveredItem] = useState<any | null>(null);
  const { closeSidebar } = useContext(SidebarContext);
  const [tesoreria] = useState(response.role);
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const accesos_nav = response.acceso;

  const handleListItemClick = (index: any) => {
    //debugger;
    idSelectedIndexMenu(index);
    navigate(menuItems[index].rutaPrincipal);
  };

  const menuItems = [
    {
      imgPasivo: inicioPasivo,
      imgHover: inicioHover,
      imgActivo: inicioActivo,
      descripcion: 'Inicio',
      rutaPrincipal: '/menu',
      opciones: [],
      activo: accesos_nav.comprobantes,
      disabled: false // Habilitado
    },
    {
      imgPasivo: docPasivo,
      imgHover: docHover,
      imgActivo: docActivo,
      descripcion: 'Comprobantes de Pago',
      rutaPrincipal: '/menu/comprobantes',
      opciones: [],
      activo: accesos_nav.comprobantes,
      disabled: false // Habilitado
    },
    {
      imgPasivo: bajaPasivo,
      imgHover: bajaHover,
      imgActivo: bajaActivo,
      descripcion: 'Reporte de Bajas',
      rutaPrincipal: '/menu/resumenbajas',
      opciones: [],
      activo: accesos_nav.bajas,
      disabled: false // Habilitado
    },
    {
      imgPasivo: guiaPasivo,
      imgHover: guiaHover,
      imgActivo: guiaActivo,
      descripcion: 'Guías',
      rutaPrincipal: '/menu/guiasremision',
      opciones: [],
      activo: accesos_nav.guias,
      disabled: false // Habilitado
    },
    {
      imgPasivo: cobranzaPasivo,
      imgHover: cobranzaHover,
      imgActivo: cobranzaActivo,
      descripcion: 'Administración de Locales',
      rutaPrincipal: '/menu/admCobranza',
      opciones: [],
      activo: tesoreria === 'COBRANZA' || tesoreria === 'ADMIN' ? true : false,
      disabled: false // Habilitado
    },
    {
      imgPasivo: repositorioPasivo,
      imgHover: repositorioHover,
      imgActivo: repositorioActivo,
      descripcion: 'Repositorio de Comprobantes',
      rutaPrincipal: '/menu/repositorio',
      // rutaPrincipal: "/status/404",
      opciones: [],
      //activo: tesoreria === 'COBRANZA' ? false : true,
      activo: accesos_nav.repositorio,
      disabled: false // Habilitado
    },
    {
      imgPasivo: ConsultasPasivo,
      imgHover: ConsultasHover,
      imgActivo: ConsultasActivo,
      descripcion: 'Consulta de deudas con Infomática',
      rutaPrincipal: '/menu/Consultas',
      opciones: [],
      //activo: tesoreria === 'COBRANZA' || tesoreria === 'ADMIN' ? true : false,
      //activo: accesos_nav.consultas,
      activo: true,
      disabled: false // Habilitado
    },

  ];

  const navigate = useNavigate();

  const handleMouseEnter = (item: any) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  return (
    <Box
      className={classes.root}
      sx={{
        width: '68px !important',
        paddingTop: {
          xs: '6px',
          sm: '6px',
          md: '40px'
        }
      }}
    >
      <ButtonSidebar
        onClick={closeSidebar}
        bgColor={themeMode.colors.Activo}
        hoverColor={themeMode.colors.Hover}
        iconName={sbClose}
      />

      <List component="nav">
      {menuItems.map((o, index) => ( o.activo === true ?
          <ListItem
            key={index}
            className={classes.listItem}
            onMouseEnter={() => !o.disabled && handleMouseEnter(index)} // Agrega verificación aquí
            onMouseLeave={handleMouseLeave}
            onClick={() => {
              if (!o.disabled) {
                handleListItemClick(index); // Maneja clic solo si no está deshabilitado
              }
            }}
            sx={{
              cursor: o.disabled ? 'not-allowed' : 'pointer',
              opacity: o.disabled ? 0.5 : 1,
              marginTop: {
                xs: '16px',
                sm: '16px',
                md: '0px'
              },
              marginBottom: {
                xs: '12px',
                sm: '12px',
                md: '30px'
              },
              '&:hover': {
                // Esto elimina el efecto hover para elementos deshabilitados
                backgroundColor: o.disabled ? 'transparent' : undefined
              }
            }}
          >
            {/* iconos del Menu */}
            <Box
              component={'img'}
              // src={
              //   hoveredItem === index ? o.imgHover
              //     : o.disabled
              //     ? o.imgPasivo // Imagen deshabilitada
              //     : indexRuta === index
              //     ? o.imgActivo
              //     : o.imgPasivo
              // }

              src={hoveredItem === index ? o.imgHover :
                selectedIndexMenu === index ? o.imgActivo : o.imgPasivo}

              sx={{
                boxShadow:
                  hoveredItem === index && !o.disabled
                    ? '0px 4px 14px 0px rgba(30, 41, 71, 0.16)'
                    : 'none',
                width: '68px',
                height: '56px'
              }}
            />

            {hoveredItem === index && (
              <Paper
                elevation={3}
                className={classes.paper}
                sx={{
                  '&.MuiPaper-root': {
                    right:
                      index === 0
                        ? '-83px' : index === 1
                        ? '-233px' : index === 2
                        ? '-177px' : index == 3
                        ? '-86px' : index === 4
                        ? '-253px' : index === 5
                        ? '-282px'
                        //: '-250px',
                        : '-328px',
                    width: 'auto',
                    borderRadius: '0px  8px  8px 0px',
                    background: themeMode.colors.Claro
                  }
                }}
              >
                <List >
                  <ListItem
                    className={classes.listItem}
                    onClick={
                      o.opciones.length === 0
                        ? () => {
                            navigate("/menu/inicio");
                          }
                        : () => {}
                    }
                    sx={{
                      height: "56px",
                      cursor:
                        o.opciones.length === 0 && !o.disabled
                          ? "pointer"
                          : "default", // Verifica si no está deshabilitado
                    }}
                  >
                    <Box
                      sx={{
                        padding: "0px 20px 0px 20px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: themeMode.colors.Activo,
                          fontFamily: "Poppins",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "24px",
                        }}
                      >
                        {o.descripcion}
                      </Typography>
                    </Box>
                  </ListItem>
                  {o.opciones.length > 0
                    ? o.opciones.map((opciones, index) => (
                        <ListItemButton
                          className={classes.listItem}
                          key={index}
                          href={opciones.link}
                          sx={{
                            height: "48px",
                            width: "auto",
                            display: "flex",
                            alignItems: "center",
                            cursor: o.disabled ? "not-allowed" : "pointer", // Cambia el cursor si está deshabilitado
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius:
                                index === o.opciones.length - 1 ? "0px 0px 8px 0px" : "0px",
                              padding: "0px 20px 0px 20px",
                              color: themeMode.colors.Neutro,
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "20px",
                              height: "100%",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              cursor: o.disabled ? "not-allowed" : "pointer", // Cambia el cursor si está deshabilitado
                              ":hover": {
                                color: themeMode.colors.Pasivo,
                                backgroundColor: o.disabled
                                  ? "transparent"
                                  : themeMode.colors.Hover,
                              },
                            }}
                          >
                            {opciones.label}
                          </Box>
                        </ListItemButton>
                      ))
                    : ""}
                </List>
              </Paper>
            )}
          </ListItem>
       : ''
      ))}
      </List>
    </Box>
  );
};

export default SidebarMenu;
