export const CONFIG ={
  // url: 'http://192.168.3.23/',    
  url: 'https://api.infofactperu.pe/',    
  url_socket: 'https://api.infofactperu.pe/notify',
  versionApi: 'api',
  
  url_externaAuth: "https://sweb3.grupotsiperu.com.pe:8905/erp/",
  versionApi_externaAuth: 'api',
  url_externa: "https://apipublic.grupotsiperu.com.pe:8911/",
  versionApi_externa: 'api'
};
