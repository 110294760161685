import React, { useState } from 'react'
import CSS from 'csstype'
import { InputBase } from '@material-ui/core';
import { makeStyles } from "@mui/styles";
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { kMaxLength } from 'buffer';
import Box from '@mui/material/Box/Box';

interface Props {
  title: string
  label: string
  titleInput: string
  valueInput?: string
  nameValue: string
  handleChangeInputValues: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  textError?: any
  type: string
  required: boolean
  maxLength?: string
  minLength?: string
  value?: string
  disabled?: boolean
}

const TextFieldStyle: React.FC<Props> = ({ disabled, title, label, titleInput, nameValue, handleChangeInputValues, error, textError, type, required, maxLength, minLength, value }: Props) => {
  return (
    <Box sx={{
      height: { xs: '48px', sm: '48px', md: '48px', lg: '48px', xl: '48px' },
      width: '100%',
      // width: { xl: '460px', lg: '460px', md: '460px', sm: '400px', xs: '320px' },
    }} >
      <TextField
        variant="standard"
        label={label}
        placeholder={titleInput}
        type={type}
        name={nameValue}
        defaultValue={value}
        value={value}
        onChange={handleChangeInputValues}
        {...(disabled && { disabled: true, helperText: disabled })}

        {...(error && { error: true, helperText: error })}
        InputProps={{
          // startAdornment: <AccountCircle />, // <== adjusted this
          disableUnderline: true, // <== added this 
        }}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
        }}
        sx={{
          width: '100%',
          paddingBottom: '0px',
          '&.MuiFormControl-root': {
            borderRadius: '4px',
            height: { xs: '48px', sm: '48px', md: '48px', lg: '48px', xl: '48px' },
            boxShadow: disabled ? '0px 0px 0px 0px #fff' : '0px 4px 12px 0px rgba(25, 67, 127, 0.12)',
            border: disabled ? '1px solid #EFF1F4' : '1px solid var(--neutralgrisclaro, #E2E5E8)',
            background: disabled ? 'var(--neutralfondos, #F8F9FC)' : '',
            ":hover": {
              boxShadow: '0px 0px 0px 0px rgba(25, 67, 127, 0.12)',
              backgroundColor: disabled ? '' : 'var(--colorHoverSecondary-Green)',
              // border: '1px solid var(--colorHoverText-Green)',
              border: disabled ? '' : '1px solid var(--colorHoverText-Green)',

            }
          },
          '& .MuiFormLabel-root': {
            marginTop: '-6px',
            paddingTop: value !== '' ? '20px' : '0px',
            paddingLeft: '14px',
            fontFamily: 'Poppins',
            fontWeight: value !== '' ? 500 : 300,
            fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px', xl: '14px' },
            lineHeight: { xs: '20px', sm: '20px', md: '24px', lg: '24px', xl: '24px' },
            color: disabled ? 'var(--neutralhover, #A7B1BF)' : value !== '' ? 'var(--colorPrimary-Black)' : 'var(--colorPrimary-Gray)',
            '&.Mui-focused': {
              fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px', xl: '14px' },
              lineHeight: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' },
              color: 'var(--colorPrimary-Black)',
              fontFamily: 'Poppins',
              fontWeight: 500,
              paddingLeft: '14px',
              paddingTop: value !== '' ? '23px' : '23px',
            },
          },
          "& .MuiInputBase-root": {
            height: '100%',
            fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
            lineHeight: { xs: '14px', sm: '14px', md: '18px', lg: '18px', xl: '18px' },
            color: 'var(--colorPrimary-Black)',
            fontFamily: 'Poppins',
            fontWeight: 300,
            marginTop: '0px',
            paddingTop: value !== '' ? '20px' : '20px',
            paddingLeft: '12px',
            '& ::placeholder': {
              fontFamily: 'Poppins',
              fontWeight: 300,
              fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px', xl: '16px' },
              lineHeight: { xs: '20px', sm: '20px', md: '24px', lg: '24px', xl: '24px' },
              color: 'var(--colorPrimary-Gray)'
            }
          }
        }}
      />
    </Box>
  )
}
export default TextFieldStyle