import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, useTheme } from '@mui/material';
import { themes } from 'src/config/Icons';

interface ScrollbarProps {
  className?: string;
  children?: ReactNode;
  isDarkMode?: any;
}

const Scrollbar: FC<ScrollbarProps> = ({
  isDarkMode,
  className,
  children,
  ...rest
}) => {
  const theme = useTheme();
  const themeMode = isDarkMode ? themes.dark : themes.light;
  return (
    <Scrollbars
      autoHide
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              background: themeMode.colors.Claro,
              borderRadius: 0,
              transition: `${theme.transitions.create(['background'])}`,
              '&:hover': {
                background: themeMode.colors.Activo
              }
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
