import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { TokenProvider } from './contexts/UserContext';
import Ruta from './app/router';

function App() {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <TokenProvider>
          <Ruta />
        </TokenProvider>
        {/* {content}*/}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
