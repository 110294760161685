import { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import SidebarLayout from 'src/layouts/SidebarLayout';
import LoaderScreen from 'src/content/pages/Status/Loading/LoaderScreen';

import ProtectedRoute from '../authenticate/protectedRoutes';
import PublicRoute from '../authenticate/publicRoutes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/pages/Login')));
const Comprobantes = Loader(lazy(() => import('src/content/menu/Comprobantes')));
const RegistraComprobantes = Loader( lazy(() => import('src/content/menu/Comprobantes/RegistraComprobantes')));
const Home = Loader(lazy(() => import('src/content/home_index')));
const ResumenBajas = Loader(lazy(() => import('src/content/menu/ResumenBajas')));
const GuiasRemision = Loader(lazy(() => import('src/content/menu/GuiasRemision')));
const Cobranza = Loader(lazy(() => import('src/content/menu/Cobranzas')));
const Status404 = Loader( lazy(() => import('src/content/pages/Status/Status404')));
const ConsultasComprobante = Loader(lazy(() => import('src/content/pages/Consultas')));
const Repositorio = Loader(lazy(() => import('src/content/menu/Repositorio')));
const Consultas = Loader(lazy(() => import('src/content/menu/Consultas/Consultas')));

const Ruta = () => {
  return (
    <Routes>
      {/* ROUTE DEL MAIN (PUBLICAS) */}
      <Route path="consulta" element={<ConsultasComprobante />} />
      <Route path="status/404" element={<Status404 />} />
      <Route path="*" element={<Status404 />} />
      {/* ROUTE DEL MAIN (PRIVADAS) */}
      <Route path="/" element={ <PublicRoute> <Login />  </PublicRoute>} />
      <Route path="menu" element={ <ProtectedRoute>  <SidebarLayout /> </ProtectedRoute> } >
       {/* rutas hijas */}
        <Route path="/menu" element={ <ProtectedRoute> <Home /> </ProtectedRoute>}/>
        <Route path="/menu/comprobantes" element={ <ProtectedRoute><Comprobantes /> </ProtectedRoute> } />
        <Route path="/menu/comprobantes/registra" element={ <ProtectedRoute>  <RegistraComprobantes /> </ProtectedRoute> } />
        <Route path="/menu/resumenbajas"  element={  <ProtectedRoute> <ResumenBajas /> </ProtectedRoute> } />
        <Route path="/menu/guiasremision"  element={<ProtectedRoute>  <GuiasRemision /> </ProtectedRoute> } />
        <Route  path="/menu/repositorio" element={ <ProtectedRoute>  <Repositorio /> </ProtectedRoute> } />
        <Route path="/menu/admCobranza" element={ <ProtectedRoute> <Cobranza /> </ProtectedRoute>  } />
        <Route path="/menu/Consultas" element={ <ProtectedRoute> <Consultas /> </ProtectedRoute>  } />
      </Route>
    </Routes>
  );
};
export default Ruta;
