import { FC, ReactNode, useContext } from 'react';
import { Box, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import LoaderScreen from 'src/content/pages/Status/Loading/LoaderScreen';
import { TokenContext } from 'src/contexts/UserContext';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { loading } = useContext(TokenContext);
  return (
    <>
      <Header />
      <Sidebar />
      <Box
        sx={{
          maxWidth: '100%',
          position: 'relative',
          display: 'collapse',
          background: 'white',
          [theme.breakpoints.up('md')]: {
            ml: `${theme.sidebar.width}`
          }
        }}
      >
        <Outlet />
      </Box>
      {loading === true ? <LoaderScreen /> : ''}
    </>
  );
};
export default SidebarLayout;
