import { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  onClick: () => void;
  bgColor?: string;
  hoverColor?: string;
  iconName: any;
}

const ButtonSidebar: FC<Props> = ({
  onClick,
  bgColor,
  hoverColor,
  iconName
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: {
          xs: 'flex',
          sm: 'flex',
          md: 'none'
        },
        width: '52px',
        height: '48px',
        padding: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '0px  24px  24px 0px',
        background: bgColor,
        '&:hover': {
          background: hoverColor,
          boxShadow: '0px 4px 12px 4px rgba(30, 41, 71, 0.08)'
        },
        cursor: 'pointer'
      }}
    >
      <img
        src={iconName}
        style={{
          width: '24px',
          height: '24px'
        }}
      />
    </Box>
  );
};

export default ButtonSidebar;
