import { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  bgColor: string;
  textColor: string;
  TextName: string;
  height: any;
  width?: any;
  fontSize: any;
  padding: any;
  isIcon?: any;
  iconRoute?: any;
  onClick?: any;
  cursor?: any;
  iconPosition?: 'left' | 'right';
  iconNcolor?: any;
}

export const PillTexto: FC<Props> = ({
  onClick,
  bgColor,
  textColor,
  TextName,
  height,
  fontSize,
  padding,
  width,
  isIcon,
  iconRoute,
  cursor,
  iconPosition = 'left',
  iconNcolor
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        width: width,
        height: height,
        background: bgColor,
        borderRadius: '20px',
        color: textColor,
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: fontSize,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '14px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: padding,
        gap: '4px',
        textTransform: 'capitalize',
        cursor: cursor
      }}
    >
      {isIcon && iconPosition === 'left' && (
        <Box
          component="img"
          sx={{
            width: '10px',
            height: '6px',
            WebkitMaskImage: `url(${isIcon})`,
            WebkitMaskSize: '10px 6px',
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskPosition: 'center',
            backgroundColor: iconNcolor
          }}
        />
      )}

      {TextName}

      {isIcon && iconPosition === 'right' && (
        <Box
          component="img"
          sx={{
            width: '10px',
            height: '6px',
            WebkitMaskImage: `url(${isIcon})`,
            WebkitMaskSize: '10px 6px',
            WebkitMaskRepeat: 'no-repeat',
            WebkitMaskPosition: 'center',
            backgroundColor: iconNcolor
          }}
        />
      )}
    </Box>
  );
};
