import React from 'react';
import { Box, Button, Typography, DialogContent } from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import iconUser from '../../../../assets/icons/SIDEBAR/USUARIO GENERICO-ACTIVO_36X36.svg';
import iconAmbar from '../../../../assets/icons/SIDEBAR/ALERTA AMBAR_36X36.svg';
import iconRojo from '../../../../assets/icons/SIDEBAR/ALERTA ROJA_36X36.svg';
import { TokenContext } from 'src/contexts/UserContext';
import { ResponseLogin } from 'src/models/login';
import { SidebarContext } from 'src/contexts/SidebarContext';
import sbOpen from '../../../../assets/icons/SIDEBAR/SIDEBAR ABRIR_52X48.svg';
import DialogUser from 'src/content/pages/Components/Dialog/DialogUsuario';
import fotoUser from '../../../../assets/icons/SIDEBAR/MODAL IMAGEN USUARIO_80X80.svg';
import logLocalIconHH from '../../../../assets/icons/SIDEBAR/LOCALES-HOVER_24X24.svg';
import logLocalIcon from '../../../../assets/icons/SIDEBAR/MODAL USUARIO-LOCAL_24X24.svg';
import logOutIcon from '../../../../assets/icons/SIDEBAR/MODAL USUARIO-CERRAR SESION-PASIVO_24X24.svg';
import logOutIconAA from '../../../../assets/icons/SIDEBAR/MODAL USUARIO-CERRAR SESION-ACTIVO_24X24.svg';
import DialogScreen from 'src/content/pages/Components/Dialog';
import imagenerror from '../../../../assets/gifs/ALERTA CARTA_112X112.gif';
import ColorButtonStyleModal from 'src/content/pages/Components/ColorButton/ColorButtonStyleModal';
import logoInfoPC from '../../../../assets/icons/SIDEBAR/LOGO NAVBAR-PC_120X24.svg';
import logoInfoMV from '../../../../assets/icons/SIDEBAR/LOGO NAVBAR-MOVIL_27X40.svg';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ModalAlerta from 'src/content/pages/Components/Dialog/ModalAlerta';
import { Locales } from 'src/models/localesCobranza';
import { getLocalesCliente } from 'src/app/services/localesCobranza.service';
import DrawerCertificadoLocal from 'src/content/pages/Components/Dialog/Drawer';
import { themes } from 'src/config/Icons';
import ButtonSidebar from 'src/content/pages/Components/Buttons/ButtonSidebar';
import { PillTexto } from 'src/content/pages/Components/Pill/PillTexto';
import DialogCerrarSesion from 'src/content/pages/Components/Dialog/DialogCerrarSesion';

interface Props {
  response: ResponseLogin;
  isDarkMode?: Boolean;
}

const HeaderMenu = ({ response, isDarkMode }: Props) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;
  const { idToken, idRuta, idResponseLogin, idLoading, token ,idTokenExterno} =
    useContext(TokenContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const [localesArray, setlocalesArray] = useState<Locales[]>([]);
  const [logout, setlogout] = useState(false);
  const [local_in, setlocal_in] = useState(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const usuario = response.name
    .toLowerCase()
    .trim()
    .split(' ')
    .map((v) => v[0].toUpperCase() + v.substr(1))
    .join(' ');
  const rol_user = response.role
    .toLowerCase()
    .trim()
    .split(' ')
    .map((v) => v[0].toUpperCase() + v.substr(1))
    .join(' ');
  const [estado, setEstado] = useState(response.certificado.estado);
  const [fVencimiento, setFVencimiento] = useState(
    response.certificado.fechavencimiento
  );
  const [openAlerta, setOpenAlerta] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleCerrarSesion = () => {
    idRuta(undefined);
    idToken(undefined);
    idResponseLogin(undefined);
    idTokenExterno(undefined);
    window.location.reload();
  };

  const handleopenSesion = () => {
    setOpenModal(true);
    setOpen(false);
  };

  const handleOpenUser = () => {
    setOpen(true);
    setlogout(false);
    setlocal_in(false);
  };

  const handleClickAlerta = () => {
    setOpenAlerta(estado !== '0' && response.role != 'CONTADOR' ? true : false);
  };
  const [value, setValue] = useState('Dione');

  const fetchLocales = async () => {
    try {
      idLoading(true);
      setlocalesArray([]);
      const LocalesConsulta = await getLocalesCliente(token);
      if (LocalesConsulta !== undefined) {
        setlocalesArray(LocalesConsulta.data);
        idLoading(false);
      }
    } catch (error: any) {
      setlocalesArray([]);
      console.log(error);
      const response = error.message;
      console.log('fetchCOBRANZA', response);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(false);
    fetchLocales();
    setOpenDrawer(true);
  };

  useEffect(() => {
    window.addEventListener('load', handleClickAlerta);
    return () => {
      window.removeEventListener('load', handleClickAlerta);
    };
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          textAlign: 'right',
          height: '100%',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '20px'
          }}
        >
          {/*Menu Slidar */}
          <ButtonSidebar
            onClick={toggleSidebar}
            bgColor={themeMode.colors.Activo}
            hoverColor={themeMode.colors.Hover}
            iconName={sbOpen}
          />

          <Box
            sx={{
              display: {
                xs: 'flex',
                sm: 'flex',
                md: 'none',
                lg: 'none',
                xl: 'none'
              },
              textAlign: 'left'
            }}
          >
            <Box component={'img'} src={logoInfoMV} />
          </Box>
        </Box>
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'flex',
              lg: 'flex',
              xl: 'flex'
            },
            width: '100%',
            textAlign: 'left'
          }}
        >
          <Box component={'img'} src={logoInfoPC} />
        </Box>

        <Box display={'flex'} width={'100%'} justifyContent={'right'}>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
                xl: 'block'
              },
              paddingRight: '12px',
              alignSelf: 'center'
            }}
          >
            <Typography
              sx={{
                color: ' #232323',
                textAlign: 'right',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '20px'
              }}
            >
              {response != null || response != undefined
                ? response.name
                : 'usuario'}
            </Typography>
            <Typography
              sx={{
                color: ' #61B09D',
                textAlign: 'right',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 300,
                lineHeight: '16px'
              }}
            >
              {response != null || response != undefined ? rol_user : 'usuario'}
            </Typography>
          </Box>
          <Box>
            <Box
              component={'img'}
              src={iconUser}
              height={'36px'}
              width={'36px'}
              onClick={handleOpenUser}
              style={{ cursor: 'pointer' }}
            />
          </Box>

          {
            <Box
              sx={{
                pl: { xs: '8px', sm: '8px', md: '12px', lg: '12px', xl: '12px' }
              }}
            >
              {estado !== '0' ? (
                <img
                  id="alertaImg"
                  src={
                    estado == '1' || estado == '3'
                      ? iconAmbar
                      : estado == '2'
                      ? iconRojo
                      : ''
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={handleClickAlerta}
                />
              ) : (
                ''
              )}
            </Box>
          }
        </Box>
      </Box>

      <DialogUser
        open={isOpen}
        handleClose={() => setOpen(false)}
        paper={{
          sx: {
            borderRadius: '10px',
            width: '360px',
           // height: response.role == 'COBRANZA' ? '384px' : '384px',
            '&.MuiPaper-root': {
              margin: {
                xs: '80px 0px 0px 0px',
                sm: '80px 20px 0px 0px',
                md: '80px 20px 0px 0px',
                lg: '80px 20px 0px 0px',
                xl: '80px 20px 0px 0px'
              },
              boxShadow: '0px 0px 0px 0px'
            }
          }
        }}
        direction={'down'}
        contentOfDialog={
          <DialogContent
            sx={{
              padding: '20px 0px 0px 0px'
            }}
          >
            {/*Empresa */}
            <Box padding={'0px 20px'}>
              <Box
                sx={{
                  backgroundColor: '#2FB699',
                  display: 'flex',
                  width: '320px',
                  height: '28px',
                  padding: '10px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  borderRadius: '14px'
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    color: '#FFF',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '14px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {response != null || response != undefined
                    ? response.empresa.name
                    : 'usuario'}
                </Typography>
              </Box>
            </Box>
            {/*USUARIO */}
            <Box
              sx={{
                display: 'flex',
                padding: '20px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
                borderBottom: '1px solid #ADE0D4'
              }}
            >
              {/*IMAGEN */}
              <img src={fotoUser} style={{ paddingBottom: '8px' }} />

              {/*DATOS */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '4px',
                  alignSelf: 'stretch'
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    color: '#2AA288',
                    textAlign: 'center',
                    textOverflow: 'ellipsis',
                    fontFamily: 'Montserrat',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px'
                  }}
                >
                  {usuario != null || usuario != undefined
                    ? usuario.length > 10
                      ? usuario.substring(0, 24) + '...'
                      : usuario
                    : 'usuario'}
                </Typography>
                <Typography
                  sx={{
                    color: '#88919E',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '16px'
                  }}
                >
                  {response != null || response != undefined
                    ? rol_user
                    : 'usuario'}
                </Typography>
                <Typography
                  sx={{
                    pt: '4px',
                    color: ' #88919E',
                    textAlign: 'center',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '16px'
                  }}
                >
                  {response != null || response != undefined
                    ? response.username
                    : 'usuario'}
                </Typography>
              </Box>
            </Box>

            {/*MENUS USUARIO */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'stretch'
              }}
            >
                            
              {/*menus/alertas estado*/}
              { response.role != 'CONTADOR' ? (
                <Box
                  sx={{
                    display: 'flex',
                    height: '48px',
                    padding: '0px 20px 0px 4px',
                    alignItems: 'center',
                    gap: '20px',
                    alignSelf: 'stretch',
                    borderBottom: '1px solid #F2F2F2'
                  }}
                >
                  {/*estado */}
                  <Box
                    sx={{
                      display: 'flex',
                      aligaItems: 'center',
                      gap: '12px',
                      flex: '1 0 0'
                    }}
                  >
                    {/*dot */}                  
                  <Box
                    sx={{
                      display: 'flex',
                      width: '24px',
                      height: '20px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '10px'
                    }}
                  >
                    <Brightness1Icon
                      sx={{
                        height: '12px',
                        width: '12px',
                        color:
                          estado == '0'
                            ? ' #42C67E'
                            : estado == '1' || estado == '3'
                            ? ' #F5A458'
                            : ' #FF655A'
                      }}
                    />
                  </Box>

                  {/*texto */}
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '20px'
                    }}
                  >
                    Certificado{' '}
                    {estado == '0'
                      ? 'vigente hasta '
                      : estado == '1' || estado == '3'
                      ? 'por vencer el '
                      : 'vencido el '}
                  </Typography>
                  </Box>
                  {/*pill */}
                  <Box sx={{ marginLeft: 'auto' }}>
                    {estado == '0' ? ( //ESTADO VIGENTE (VERDE)
                      <PillTexto
                        bgColor={'#E7F9EF'}
                        textColor={'#3CB473'}
                        TextName={fVencimiento.toString()}
                        height={'24px'}
                        width={'100%'}
                        fontSize={'13px'}
                        padding={'10px 12px'}
                      />
                    ) : estado == '1' || estado == '3' ? ( // ESTADO PROXIMO (AMBAR)
                      <PillTexto
                        bgColor={'#FFF5F0'}
                        textColor={'#FFA826'}
                        TextName={fVencimiento.toString()}
                        height={'24px'}
                        width={'100%'}
                        fontSize={'13px'}
                        padding={'10px 12px'}
                      />
                    ) : estado == '2' ? ( // ESTADO CRITICO (ROJO)
                      <PillTexto
                        bgColor={'#FFECEB'}
                        textColor={'#FF655A'}
                        TextName={fVencimiento.toString()}
                        height={'24px'}
                        width={'100%'}
                        fontSize={'13px'}
                        padding={'10px 12px'}
                      />
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
                  ) : (
                    ''
              )}

              {/*SUBMENU */}
              { response.role != 'CONTADOR' ? (
                  <Box
                    onClick={handleDrawerOpen}
                    onMouseEnter={() => setlocal_in(true)}
                    onMouseLeave={() => setlocal_in(false)}
                    sx={{
                      display: response.role == 'COBRANZA' ? 'none' : 'flex',
                      padding: '12px 20px',
                      alignItems: 'center',
                      gap: '20px',
                      alignSelf: 'stretch',
                      cursor: 'pointer',
                      ':hover': {
                        backgroundColor: local_in ? ' #69C4DE' : '#fff'
                      }
                    }}
                  >
                    <img src={local_in ? logLocalIconHH : logLocalIcon} />

                    <Typography
                      sx={{
                        color: local_in ? '#fff' : ' #88919E',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px'
                      }}
                    >
                      Mis locales
                    </Typography>
                  </Box>
              ) : (
                ''
              )}

              {/*SUBMENU */}
              <Box
                onClick={handleopenSesion}
                onMouseEnter={() => setlogout(true)}
                onMouseLeave={() => setlogout(false)}
                sx={{
                  display: 'flex',
                  padding: ' 12px 20px',
                  alignItems: 'center',
                  gap: '20px',
                  alignSelf: 'stretch',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: logout ? ' #69C4DE' : '#fff'
                  }
                }}
              >
                <Box
                  component={'img'}
                  src={logout ? logOutIconAA : logOutIcon}
                  sx={{
                    width: '24px',
                    height: '24px'
                  }}
                />
                <Typography
                  sx={{
                    color: logout ? '#fff' : ' #88919E',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'
                  }}
                >
                  Cerrar Sesión
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        }
      />
      {/*MODAL DE DESEAS TEMINAR TU SESIÓN */}
      <DialogCerrarSesion
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        handleCerrarSesion={handleCerrarSesion}
      />

      {/*MODAL ALERTA */}
      <ModalAlerta
        id="ringtone-menu"
        keepMounted
        open={openAlerta}
        onClose={() => setOpenAlerta(false)}
        value={value}
        estado={estado}
        fVencimiento={fVencimiento}
        modalLocales={openDrawer}
        setmodalLocales={setOpenDrawer}
        handleArrayLocal={handleDrawerOpen}
        datos={localesArray}
        paper={{
          sx: {
            borderRadius: '8px 0px 0px 8px',
            margin: '0px',
            marginTop: '60px',
            boxShadow: 0
          }
        }}
      />
      {/*MODAL CERTIFICADO DIGITAL */}
      <DrawerCertificadoLocal
        response={response}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        datos={localesArray}
      />
    </>
  );
};

export default HeaderMenu;
