import React from "react";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

// Exporta la transición hacia la izquierda
export const Transitionleft = React.forwardRef(function Transition(
  props: SlideProps & TransitionProps & { children: React.ReactElement<any, any>; },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

// Exporta la transición hacia la derecha
export const Transitionright = React.forwardRef(function Transition(
  props: SlideProps & TransitionProps & { children: React.ReactElement<any, any>; },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

// Exporta la transición hacia arriba
export const Transitionup = React.forwardRef(function Transition(
  props: SlideProps & TransitionProps & { children: React.ReactElement<any, any>; },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Exporta la transición hacia abajo
export const Transitiondown = React.forwardRef(function Transition(
  props: SlideProps & TransitionProps & { children: React.ReactElement<any, any>; },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});




